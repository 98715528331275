.products-carousel {
    height: 100%;
    width: 100%;
    .carousel.carousel-slider {
        height: 100%;
        width: 100%;
        .slider-wrapper {
            height: 100%;
            width: 100%;
            .slider {
                height: 100%;
                width: 100%;
                .slide {
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    text-align: center;
                    height: 100%;
                    line-height: 1.5;
                    font-size: 1.5em;
                    &::after {
                        content: '';
                        position: absolute;
                        top: 0;
                        bottom: 0;
                        left: 0;
                        right: 0;
                        background-image: linear-gradient(
                            40deg,
                            #00000077,
                            #ffffff00
                        );
                    }
                    .info-shell {
                        position: absolute;
                        bottom: 120px;
                        left: 0;
                        width: 60%;
                        height: auto;
                        text-align: left;
                        padding: 20px;
                        z-index: 1;
                        h5 {
                            color: #ffffff;
                            font-size: 32px;
                            font-weight: 400;
                        }
                        p {
                            color: #ffffff;
                            font-size: 16px;
                            font-weight: 300;
                        }
                    }
                }
            }
        }
        .control-dots {
            margin-bottom: 40px;
            // .dot {
            //     transition: width .4s ease;
            //     border-radius: 8px;
            //     box-shadow: 1px 1px 2px rgba(0, 0, 0, 0.4);
            //     &.selected {
            //         width: 16px;
            //         border-radius: 8px;
            //         transition: width .4s ease;
            //     }
            // }
        }
        .arrow {
            position: absolute;
            margin-right: 20px;
            width: 32px;
            height: 32px;
            border-radius: 24px;
            background-color: rgba(255, 255, 255, 0.8);
            bottom: 40px;
            z-index: 1;
            box-shadow: 1px 1px 2px rgba(0, 0, 0, 0.4);
            cursor: pointer;
            opacity: 1;
            transition: opacity 0.4s ease-in;
            display: flex;
            align-items: center;
            justify-content: center;
            color: #111;
            &.disabled {
                cursor: default;
                opacity: 0;
                transition: opacity 0.4s ease-out;
            }
            &.nxt {
                right: 20px;
                color: #111;
            }
            &.prv {
                left: 20px;
            }
        }
    }
}
.animate {
    animation-duration: 0.75s;
    animation-duration: 1s; //running slower to show effect
    animation-delay: 0.5s;
    animation-name: animate-fade;
    animation-timing-function: cubic-bezier(0.26, 0.53, 0.74, 1.48);
    animation-fill-mode: backwards;
}
/* Fade In */
.animate.fade {
    animation-name: animate-fade;
    animation-timing-function: ease;
}
@keyframes animate-fade {
    0% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}

/* Pop In */
.animate.pop {
    animation-name: animate-pop;
}
@keyframes animate-pop {
    0% {
        opacity: 0;
        transform: scale(0.5, 0.5);
    }
    100% {
        opacity: 1;
        transform: scale(1, 1);
    }
}

/* Blur In */
.animate.blur {
    animation-name: animate-blur;
    animation-timing-function: ease;
}
@keyframes animate-blur {
    0% {
        opacity: 0;
        filter: blur(15px);
    }
    100% {
        opacity: 1;
        filter: blur(0px);
    }
}

/* Glow In */
.animate.glow {
    animation-name: animate-glow;
    animation-timing-function: ease;
}
@keyframes animate-glow {
    0% {
        opacity: 0;
        filter: brightness(3) saturate(3);
        transform: scale(0.8, 0.8);
    }
    100% {
        opacity: 1;
        filter: brightness(1) saturate(1);
        transform: scale(1, 1);
    }
}

/* Grow In */
.animate.grow {
    animation-name: animate-grow;
}
@keyframes animate-grow {
    0% {
        opacity: 0;
        transform: scale(1, 0);
        visibility: hidden;
    }
    100% {
        opacity: 1;
        transform: scale(1, 1);
    }
}

/* Splat In */
.animate.splat {
    animation-name: animate-splat;
}
@keyframes animate-splat {
    0% {
        opacity: 0;
        transform: scale(0, 0) rotate(20deg) translate(0, -30px);
    }
    70% {
        opacity: 1;
        transform: scale(1.1, 1.1) rotate(15deg);
    }
    85% {
        opacity: 1;
        transform: scale(1.1, 1.1) rotate(15deg) translate(0, -10px);
    }
    100% {
        opacity: 1;
        transform: scale(1, 1) rotate(0) translate(0, 0);
    }
}

/* Roll In */
.animate.roll {
    animation-name: animate-roll;
}
@keyframes animate-roll {
    0% {
        opacity: 0;
        transform: scale(0, 0) rotate(360deg);
    }
    100% {
        opacity: 1;
        transform: scale(1, 1) rotate(0deg);
    }
}

/* Flip In */
.animate.flip {
    animation-name: animate-flip;
    transform-style: preserve-3d;
    perspective: 1000px;
}
@keyframes animate-flip {
    0% {
        opacity: 0;
        transform: rotateX(-120deg) scale(0.9, 0.9);
    }
    100% {
        opacity: 1;
        transform: rotateX(0deg) scale(1, 1);
    }
}

/* Spin In */
.animate.spin {
    animation-name: animate-spin;
    transform-style: preserve-3d;
    perspective: 1000px;
}
@keyframes animate-spin {
    0% {
        opacity: 0;
        transform: rotateY(-120deg) scale(0.9, 0.9);
    }
    100% {
        opacity: 1;
        transform: rotateY(0deg) scale(1, 1);
    }
}

/* Slide In */
.animate.slideIn {
    animation-name: animate-slide;
}
@keyframes animate-slide {
    0% {
        opacity: 0;
        transform: translate(0, 20px);
    }
    100% {
        opacity: 1;
        transform: translate(0, 0);
    }
}

/* Drop In */
.animate.drop {
    animation-name: animate-drop;
    animation-timing-function: cubic-bezier(0.77, 0.14, 0.91, 1.25);
}
@keyframes animate-drop {
    0% {
        opacity: 0;
        transform: translate(0, -300px) scale(0.9, 1.1);
    }
    95% {
        opacity: 1;
        transform: translate(0, 0) scale(0.9, 1.1);
    }
    96% {
        opacity: 1;
        transform: translate(10px, 0) scale(1.2, 0.9);
    }
    97% {
        opacity: 1;
        transform: translate(-10px, 0) scale(1.2, 0.9);
    }
    98% {
        opacity: 1;
        transform: translate(5px, 0) scale(1.1, 0.9);
    }
    99% {
        opacity: 1;
        transform: translate(-5px, 0) scale(1.1, 0.9);
    }
    100% {
        opacity: 1;
        transform: translate(0, 0) scale(1, 1);
    }
}

/* Animation Delays */
.delay-1 {
    animation-delay: 0.6s;
}
.delay-2 {
    animation-delay: 0.7s;
}
.delay-3 {
    animation-delay: 0.8s;
}
.delay-4 {
    animation-delay: 0.9s;
}
.delay-5 {
    animation-delay: 1s;
}
.delay-6 {
    animation-delay: 1.1s;
}
.delay-7 {
    animation-delay: 1.2s;
}
.delay-8 {
    animation-delay: 1.3s;
}
.delay-9 {
    animation-delay: 1.4s;
}
.delay-10 {
    animation-delay: 1.5s;
}
.delay-11 {
    animation-delay: 1.6s;
}
.delay-12 {
    animation-delay: 1.7s;
}
.delay-13 {
    animation-delay: 1.8s;
}
.delay-14 {
    animation-delay: 1.9s;
}
.delay-15 {
    animation-delay: 2s;
}

@media screen and (prefers-reduced-motion: reduce) {
    .animate {
        animation: none !important;
    }
}
