// 3rd-party Plugins
@import '~highlight.js/styles/googlecode.css';
@import '~perfect-scrollbar/css/perfect-scrollbar.css';

// Theme Styles
@import './_chamasoft/_assets/sass/style.react.scss';

// Default Layout themes
// @import "./_chamasoft/_assets/sass/themes/layout/header/base/light.scss";
// @import "./_chamasoft/_assets/sass/themes/layout/header/menu/light.scss";
// @import "./_chamasoft/_assets/sass/themes/layout/brand/dark.scss";
// @import "./_chamasoft/_assets/sass/themes/layout/aside/dark.scss";

// Header themes
// Light
//@import "./_chamasoft/_assets/sass/themes/layout/header/base/light.scss";
// Dark
// @import "./_chamasoft/_assets/sass/themes/layout/header/base/dark.scss";

// Header Menu themes
// Light
//@import "./_chamasoft/_assets/sass/themes/layout/header/menu/light.scss";
// Dark
// @import "./_chamasoft/_assets/sass/themes/layout/header/menu/dark.scss";

// Brand themes
// .brand-dark {
//   @import "./_chamasoft/_assets/sass/themes/layout/brand/dark.scss";
// }
// // Light
// .brand-light {
//   @import "./_chamasoft/_assets/sass/themes/layout/brand/light.scss";
// }

// Aside themes
// Dark
//@import "./_chamasoft/_assets/sass/themes/layout/aside/dark.scss";
// Light
// @import "./_chamasoft/_assets/sass/themes/layout/aside/light.scss";

// .example-highlight {
//   padding: 0rem 1.5rem 1.75rem !important;
// }

.example-highlight > pre {
    background: none transparent !important;
    margin: 0 !important;
    padding-top: 0.5rem;
    code {
        overflow-y: auto;
        display: block;

        span {
            background: none transparent !important;
        }
    }
}

.json > pre {
    background: none #fff !important;
}

.example-code > .json {
    background: none #fff !important;
}

.symbol.symbol-45 > svg {
    width: 100%;
    max-width: 45px;
    height: 45px;
}

.react-bootstrap-table {
    overflow-x: auto;
}

.react-bootstrap-table {
    th {
        outline: none;

        &.sortable {
            .svg-icon-sort {
                opacity: 0;
            }

            &:hover {
                cursor: pointer;

                .svg-icon-sort {
                    opacity: 1;
                }
            }
        }
    }

    .table.table-head-custom thead tr {
        .sortable-active {
            color: $primary !important;
        }
    }
}

.cursor-default {
    cursor: default !important;
}

svg {
    pointer-events: none;
}

.fv-plugins-message-container {
    position: absolute;
}
.fv-plugins-message-container .fv-help-block {
    font-size: 10px;
    color: #f64e60 !important;
}
// .cs-cust-form .was-validated .form-control:invalid, .cs-cust-form .form-control.is-invalid {
//   border-color: #F64E60!important;
//   padding-right: 0;
// }
// .cs-cust-form .was-validated .form-control:valid, .cs-cust-form .form-control.is-valid {
//   border-color: #17c191!important;
//   padding-right: 0;
// }
input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
    box-shadow: 0 0 0 30px white inset !important;
    -webkit-box-shadow: 0 0 0 30px white inset !important;
}

.table.table-head-custom thead tr,
.table.table-head-custom thead th {
    letter-spacing: 0rem !important;
}
.checkbox > span {
    background-color: #c9cedc;
}
.checkbox.disabled,
.checkbox > input:disabled ~ label {
    opacity: 0.65;
}
.phone-input input.PhoneInputInput {
    border: none;
    background: none;
}
.phone-input input.PhoneInputInput:focus {
    outline: none;
}
.PhoneInputInput {
    margin-left: -33px !important;
}
.PhoneInputInput label.MuiInputLabel-shrink {
    margin-left: 0px !important;
}
.PhoneInputInput label {
    margin-left: 38px !important;
}
.PhoneInputInput input {
    padding-left: 38px;
}
.PhoneInputCountry {
    margin-top: 13px;
}
.mt-0 .MuiFormControl-marginNormal {
    margin-top: 0px !important;
}
.MuiFormControl-marginNormal {
    margin-bottom: 0px !important;
}
.b-dashed-top {
    border-top: 1px dashed #ccc;
}
.psnl-app:hover,
.list-group-item-action:hover,
.list-group-item-action:focus {
    background-color: #ebedf369 !important;
}

.file-drop-area {
    position: relative;
    display: flex;
    align-items: center;
    width: 100%;
    max-width: 100%;
    padding: 25px;
    border: 3px dashed rgba(0, 0, 0, 0.15);
    border-radius: 0.42rem;
    transition: 0.2s;
    &.is-active {
        background-color: rgba(0, 0, 0, 0.05);
    }
}

.fake-btn {
    flex-shrink: 0;
    background-color: rgba(0, 0, 0, 0.04);
    border: 1px solid rgba(0, 0, 0, 0.1);
    border-radius: 0.42rem;
    padding: 8px 15px;
    margin-right: 10px;
    font-size: 12px;
    text-transform: uppercase;
}

.file-msg {
    font-size: small;
    font-weight: 300;
    line-height: 1.4;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.file-input {
    position: absolute;
    left: 0;
    top: 0;
    height: 100%;
    width: 100%;
    cursor: pointer;
    opacity: 0;
    &:focus {
        outline: none;
    }
}
.file-drop-area-progress {
    position: absolute;
    display: flex;
    align-items: center;
    width: 100%;
    max-width: 100%;
    padding: 25px;
    border-radius: 0.42rem;
    transition: 0.2s;
    background-color: rgba(255, 255, 255, 0.8);
    height: 100%;
    z-index: 1;
    left: 0;
}

.txt-trunc {
    width: 150px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}
.req.fold .hide-extra-content {
    height: 150px;
    // padding-bottom: 40px;
    position: relative;
    overflow: hidden;
}
.req.fold .hide-extra-content .req-overlay {
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    height: 80px;
    background: rgb(243, 246, 249);
    background: linear-gradient(
        0deg,
        rgba(255, 255, 255, 1) 0%,
        rgba(255, 255, 255, 0) 100%
    );
    z-index: 1111;
    display: block;
}
.req.fold .more {
    display: block;
}
.req .hide-extra-content {
    height: auto;
}
.req .hide-extra-content .req-overlay {
    display: none;
}
.req .more {
    display: none;
}

@import './stepper.scss';

.cursor-pointer {
    cursor: pointer;
}

.excel-download {
    padding: 12px 32px 14px;
    border-radius: 3px;
    cursor: pointer;
    padding-left: 60px;
    margin-bottom: 20px;
    transition: all 0.3s ease;
}
.excel-download:hover {
    opacity: 0.75;
    transition: all 0.3s ease;
}
.hover-opacity {
    transition: all 0.3s ease;
}
.hover-opacity:hover {
    opacity: 0.75;
    transition: all 0.3s ease;
}
.btn {
    border: 2px solid transparent;
}
.rad-descr {
    margin-top: -14px;
    margin-left: 26px;
}
.rad-descr p {
    line-height: 12px;
    font-size: 80%;
}
.opacity-80 {
    opacity: 0.8;
}
.opacity-70 {
    opacity: 0.7;
}
.opacity-60 {
    opacity: 0.6;
}
.opacity-50 {
    opacity: 0.5;
}
.opacity-20 {
    opacity: 0.2 !important;
}
.form-group label._active {
    color: #3783e7;
}
.cs-phone-inp,
.cs-phone-inp .MuiInputBase-root.MuiInputBase-formControl {
    width: 100%;
}
.bg-light {
    background-color: #f5fcff !important;
}
