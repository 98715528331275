//
// Chamasoft Login
// Pages SASS files are compiled into separate css files
//

// Initialization of global variables, mixins and functions
@import '../../init';

.login.login-1.chamasoft-login {
  // Form modes
  .login-signin,
  .login-signup,
  .login-forgot {
    display: none;
  }

  &.login-signin-on {
    .login-signup {
      display: none;
    }

    .login-signin {
      display: block;
    }

    .login-forgot {
      display: none;
    }
  }

  &.login-signup-on {
    .login-signup {
      display: block;
    }

    .login-signin {
      display: none;
    }

    .login-forgot {
      display: none;
    }
  }

  &.login-forgot-on {
    .login-signup {
      display: none;
    }

    .login-signin {
      display: none;
    }

    .login-forgot {
      display: block;
    }
  }
  .chamasoft-effect {
    height: 100%;
    width: 100px;
    position: absolute;
    top: 0;
    left: -100px;
  }
}

.login-aside {
  .main-logo {
    position: absolute;
    z-index: 1;
    top: 30px;
    left: 20px;
  }
}

// Desktop view
@include media-breakpoint-up(lg) {
  .login.login-1.chamasoft-login {
    .login-aside {
      width: 100%;
      max-width: 800px;
    }

    .login-form {
      width: 100%;
      max-width: 420px;

    }

    .login-form.bank-options {
      width: 100%;
      max-width: 540px;
      margin-left: 180px;
    }
    ._full-layout {
      margin-left: 180px;
    }
  }
  .login.login-2.chamasoft-login {
    .login-form {
      width: 100%;
      max-width: 500px;
    }
  }
}

// Tablet and Mobile mode
@include media-breakpoint-between(lg, xl) {
  .login.login-1.chamasoft-login {
    .login-aside {
      width: 100%;
      max-width: 800px;
    }
  }
}

// Tablet mode
@include media-breakpoint-down(md) {
  .login.login-1.chamasoft-login {
    .login-form {
      width: 100%;
      max-width: 350px;
    }
    .login-aside {
      min-height: 320px;
      .products-carousel {
        .carousel.carousel-slider {
          .slider-wrapper {
            .slider {
              .slide {
                .info-shell {
                  width: 100%;
                  height: auto;
                  bottom: 60px;
                  h5 {
                    color: #ffffff;
                    font-size: 18px;
                    font-weight: 400;
                  }
                  p {
                    color: #ffffff;
                    font-size: 12px;
                    font-weight: 300;
                  }
                }
              }
            }
          }
          .control-dots {
            margin-bottom: 20px;
          }
          .arrow {
            position: absolute;
            width: 26px;
            height: 26px;
            bottom: 20px;
            margin-right: 0;
          }
        }
      }
    }
  }
}

// Mobile mode
@include media-breakpoint-down(xs) {
  .login.login-1.chamasoft-login {
    .login-form {
      width: 100%;
      max-width: 100%;
    }
  }
}

.entity-types {
  max-height: 320px;
  overflow-y: hidden;
  overflow-x: hidden;
  padding-left: 15px;
  margin-left: -15px;
  padding-right: 7px;
  &:hover {
    padding-right: 0;
    overflow-y: auto;
    &::-webkit-scrollbar {
      width: 7px;
    }
    &::-webkit-scrollbar-thumb {
      background: #d6d6d6;
      border-radius: 10px;
    }
    &::-webkit-scrollbar-track {
      background: #d6d6d646;
      border-radius: 10px;
    }
  }
  .rad-descr {
    margin-right: 15px;
  }
}